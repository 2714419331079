import React from "react";
import "./About.css";
import Header from "../Shared/Header/Header";
import HomeFooter from "../Shared/Footer/HomeFooter";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import aboutImg1 from "../../images/who-we-are.jpg";
import aboutImg2 from "../../images/mission.jpg";
import aboutImg3 from "../../images/vision.jpg";
import remote from "../../images/remote-control.jpg";
import rd from "../../images/r&d.jpg";

const About = () => {
  return (
    <div className="about overflow-hidden">
      <Header />

      <div className="background-about">
         <div className="aboutt-text">
          <h1 className="text-white fw-bold">
            <span className=" company-about">ABOUT US</span>
          </h1>
        </div>
      </div>

      <div className="d-flex flex-lg-row flex-md-row flex-sm-column flex-column mt-3 container p-2 w-100">
        <div className="about-container w-100 me-2 ">
          <img src={aboutImg1} className="w-100 border-bottom" alt="aboutimg" />
          <h2 className="about-header">Who we are?</h2>
          <p>
            We are Amar Source, our main office at Dhanmondi, Dhaka, Bangladesh.
            We began our journey on 2014, starting from a few individuals and
            slowly grew up to be a house of enthusiastic workers, ardent and
            passionate about what we do. We have a successful track record based
            on the past years of customer satisfaction that we have provided,
            which we hope to keep a hold onto.
          </p>
        </div>
        <div className="about-container w-100 me-2">
          <img src={aboutImg2} className="w-100 border-bottom" alt="aboutimg" />
          <h2 className="about-header">Our Mission</h2>
          <p>
            We have come a long way to fulfil our vision and mission supporting
            and providing optimum services to our clients and also carrying out
            research work. Now we wish to take Amar Source to the top and make
            it one of the best research and development institutes of
            Bnagladesh.
          </p>
        </div>
        <div className="about-container w-100 me-2">
          <img src={aboutImg3} className="w-100 border-bottom" alt="aboutimg" />
          <h2 className="about-header">Our Vision</h2>
          <p>
            The main ambition of this company is to revolutionize the
            agriculture industry of Bangladesh by making technology that is
            designed especially for Bangladesh available to everyone within this
            industry. The aim is to achieve higher rop yiele, less pollution and
            use of resources and increased worker safety.
          </p>
        </div>
      </div>

      <div className="about-background py-5 d-flex flex-lg-row flex-md-column flex-sm-column flex-column">
        <div className="about-global container ms-lg-5 ms-md-5">
          <div className="d-flex flex-lg-row flex-md-column flex-sm-column flex-column pb-4">
            <div>
              <img src={remote} className="about-research" alt="remote-control" />
            </div>
            <div className="ps-lg-3">
              <h2>Xavier Air</h2>
              <ul>
                <li className="fs-5">
                  A generic industrial automation platform for real-time remote
                  sensing and control
                </li>
                <hr className="about-hr"/>
              </ul>
            </div>
          </div>
          <div className="d-flex flex-lg-row flex-md-column flex-sm-column flex-column">
            <div>
              <img src={rd} className="about-research" alt="remote-control" />
            </div>
            <div className="ps-lg-3">
              <h2>Research and Development (R&D)</h2>
              <ul>
                <li className="fs-5">
                  Engineering Research and Product Development
                </li>
                <hr className="about-hr"/>
                <li className="fs-5">Precision Agriculture</li>
                <hr className="about-hr"/>
                <li className="fs-5">
                  Engineering Design and Fabrication Services
                </li>
                <hr className="about-hr"/>
                <li className="fs-5">Engineering Service</li>
                <hr className="about-hr"/>
                <li className="fs-5">Maintanance and repair</li>
              </ul>
            </div>
          </div>

          
        </div>
        <div className="iframe-container container ">
          <iframe
            width="660"
            height="450"
            src="https://www.youtube.com/embed/y4dNx6qZx2U?si=G53W92MMCBfhvJZq"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div className="d-flex flex-lg-row flex-md-row flex-sm-column flex-column container project-starting my-5 p-4">
        <div className="w-lg-75 mx-lg-5 text-black">
          <h1>Would you like to start a project with us?</h1>
          <p>
            We like to hear your ideas, problems, requirements and take the
            challenge to build them, solve them and work on them using latest
            and locally available resources
          </p>
          <Link className="contact-button" to="/contact">
            <FontAwesomeIcon icon={faPhone} className="pe-2" />
            Contact Us
          </Link>
        </div>

        {/* <div className="ms-lg-5 ms-md-5 ms-sm-2 ms-2 w-lg-25  w-100">
          <Link className="contact-button" to="/contact">
            <FontAwesomeIcon icon={faPhone} className="pe-2" />
            Contact Us
          </Link>
        </div> */}
      </div>

      <HomeFooter />
    </div>
  );
};

export default About;
