import {  faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import './Ideology.css';
import robot1 from "../../images/robot1.jpg";
import { Link } from "react-router-dom";



const Ideology = () => {
  return (
    <section className="overflow-hidden mt-5 pt-5">
      <div>
        <div className="d-flex pt-5 row   overflow-hidden" >

          <div className="col-lg-6 col-md-12 col-sm-12 col-12  overflow-hidden mt-5 pt-4" >
            <img
              src={robot1}
              className="w-lg-100 w-md-100 w-sm-75 w-75 h-75 ms-5"
              alt="...."
            />
          </div>
          <div
            className="mt-5  pt-5 col-lg-6 col-md-12 col-sm-12
           col-12 ps-md-5 pb-md-5 ps-sm-5 ps- pb-sm-5 ps-5 pb-5 overflow-hidden pe-5"
           >
            <h2 className="fontColor">TIMELINE</h2>
            <h1>WHAT IS ROBOTIC PROCESS AUTOMATION IN MARKET SINCE 1980?</h1>
            <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy Lorem Ipsum is of the printing and typesetting industry.
            </p>
            <div className="d-flex  flex-sm-column flex-md-row flex-lg-row flex-xs-column flex-column">
              <div>
                <h3>CUSTOMERS ASK FOR FLEXIBILITY</h3>
                <p>Lorem Ipsum is simply dummy text of the printing</p>
              </div>
              <div>
                <h3>CUSTOMERS ASK FOR FLEXIBILITY</h3>
                <p>Lorem Ipsum is simply dummy text of the printing</p>
              </div>

            </div>
            <Link>
            <button className="ideology-btn">LEARN MORE <FontAwesomeIcon className="rotation-prop" icon={faPlus} /></button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ideology;
