import React, { useEffect, useState } from "react";
import "./Home.css";
import Address from "../Address/Address";
import HomeFooter from "../Shared/Footer/HomeFooter";
import Ideology from "../Ideology/Ideology";
import ClientsFeedback from "../ClinetsFeedback/ClientsFeedback";
import {
  faDiagramProject,
  faHouseLock,
  faLightbulb,
  faPlus,
  faTractor,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Faq from "../Faq/Faq";
import Xavier from "../Xavier/Xavier";
import {
  faAutoprefixer,
  faSearchengin,
} from "@fortawesome/free-brands-svg-icons";
import Header from "../Shared/Header/Header";
import Carousel from "../Carousel/Carousel";
import CustomMessaging from "../CustomMessaging/CustomMessaging";
import { Link } from "react-router-dom";
import Choose from "../Choose/Choose";
import Partners from "../Partners/Partners";

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="overflow-hidden">
      <Header />
      <Carousel />
      <Xavier />

      <Ideology />
      <Choose />
      <Faq />

      {/* our services */}
      <section className="bg-service border mt-5">
        <div className="text-center sizing ">
          <div className="py-3">
            <h3 className="fontColor ">OUR SERVICES</h3>
            <h2 className="font-bold">SERVICES WE PROVIDE WITH BEST EFFORT</h2>
          </div>

          <div class="row  mx-2 me-10">
            <div class="col-sm-12 col-md-6 col-lg-4 mb-3 mb-sm-0  px-lg-4 px-1 ">
              <div class="card card-style pb-4 pt-1 ">
                <div class="card-body me-5">
                  <FontAwesomeIcon
                    className="fs-1 fontColor"
                    icon={faSearchengin}
                  />
                  <h5>RESEARCH WORKS</h5>
                  <p>
                    We conduct research to get a grasp of the situation under
                    study and to create an optimum solution.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-12  col-md-6 col-lg-4 mb-3 mb-sm-0  pb-4 pt-1 ">
              <div class="card card-style">
                <div class="card-body me-5 ">
                  <FontAwesomeIcon
                    className="fs-1 fontColor"
                    icon={faDiagramProject}
                  />
                  <h5>Technology Development and Product Design</h5>
                  <p>
                    We create roadmaps for future technological advancement and
                    implementing them.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 mb-3 pb-4 pt-1  px-1">
              <div class="card card-style">
                <div class="card-body me-5">
                  <FontAwesomeIcon
                    className="fs-1 fontColor pt-3"
                    icon={faTractor}
                  />
                  <h5 className="pt-2">AGRICULTURE</h5>
                  <p className="pt-4">
                    We carry out various projects related to agriculture
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-12  col-md-6 col-lg-4 mb-sm-0  px-lg-4   pt-1">
              {/* pb-4 */}
              <div class="card card-style">
                <div class="card-body me-5">
                  <FontAwesomeIcon
                    className="fs-1 fontColor"
                    icon={faLightbulb}
                  />
                  <h5>Engineering Servicing, Repair and Maintenance </h5>
                  <p>
                    We provide diversified customer needs and quick services
                    whenever required.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-12  col-md-6 col-lg-4 mb-3 mb-sm-0  pt-1">
              {/* pb-4 */}
              <div class="card card-style">
                <div class="card-body me-5">
                  <FontAwesomeIcon
                    className="fs-1 fontColor"
                    icon={faAutoprefixer}
                  />
                  <h5>Industrial Automation and Control System</h5>
                  <p>
                    We specialize in introducing electrical, mechanical and
                    computer-based systems
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 mb-3 mb-sm-0  pt-1  px-1">
              <div class="card card-style">
                <div class="card-body me-5">
                  <FontAwesomeIcon
                    className="fs-1 fontColor  pt-2"
                    icon={faHouseLock}
                  />
                  <h5 className="px-4">Security System</h5>
                  <p className="pb-3">
                    We provide smart home and customer focused security systems.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-background">
        <div className=" services-work text-light">
          <div className=" d-flex flex-lg-row flex-md-row flex-sm-column flex-column mb-5">
            <div className="about-global container ms-lg-5 ms-md-5 text-left ">
              <h3 className="pt-1 fontColor">BEST SERVICE</h3>
              <h3 className="py-2">Challenges in Developing Robot Service</h3>
              <h5 className="py-3">
                GET YOUR QUOTE OR CALL: <strong>+880 1816-688711</strong>
              </h5>
              <Link to="/contact">
                <button className="btn1">MORE ABOUT ROBOT </button>
              </Link>
            </div>

            <div className="iframe-container container pb-5 ">
              <iframe
                width="660"
                height="350"
                src="https://www.youtube.com/embed/0TPnsN9_MeU?si=dfm8SYABNWk7jzbm"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <ClientsFeedback />
      <Partners />
      <Address />
      <CustomMessaging />

      <HomeFooter />
    </div>
  );
};

export default Home;
