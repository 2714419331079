
import React from "react";
import './ClientsFeedback.css'
import img1 from "../../images/client1.jpg"
import img2 from "../../images/client2.jpg";
import img3 from "../../images/client3.jpg";
import img4 from "../../images/client4.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const ClientsFeedback = () => {
  return (
    <>
     <div className="pb-2">
     <h3 className="text-center mt-5  pt-5 fontColor "><span className="border-custom">OUR CLIENTS' SAY</span></h3>
      
     </div>
    <div
      id="carouselExampleSlidesOnly"
      class="carousel slide "
      data-bs-ride="carousel"
      data-interval="2000"
    >
      <div class="carousel-inner">
        <div class="carousel-item active ">
          <div className="row mx-lg-4">
            <div className="col-sm-6 mb-3 mb-sm-0">
              <div className="card">
                <div className="card-body my-5 px-5">
                  <div className="d-flex flex-sm-column flex-md-row flex-lg-row flex-xs-column flex-column">
                    <div>
                      <img className="carousel-img" src={img1} alt="card-img" />
                    </div>
                    <div className="ps-3 pt-3">
                      <h2>CHIRS BROS</h2>
                      <h6>EMPLOYEE</h6>
                    </div>
                  </div>

                  <p className="card-text pt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                    leo.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="card">
                <div className="card-body my-5 px-5">
                  <div className="d-flex flex-sm-column flex-md-row flex-lg-row flex-xs-column flex-column">
                    <div>
                      <img className="carousel-img" src={img2} alt="card-img" />
                    </div>
                    <div className="ps-3 pt-3">
                      <h2>KARINA MARTIN</h2>
                      <h6>EMPLOYEE</h6>
                    </div>
                  </div>

                  <p className="card-text pt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                    leo.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item active">
          <div className="row mx-lg-4">
            <div className="col-sm-6 mb-3 mb-sm-0">
              <div className="card">
                <div className="card-body my-5 px-5">
                  <div className="d-flex flex-sm-column flex-md-row flex-lg-row flex-xs-column flex-column">
                    <div>
                      <img className="carousel-img" src={img3} alt="card-img" />
                    </div>
                    <div className="ps-3 pt-3">
                      <h2>JOHN HOSE</h2>
                      <h6>EMPLOYEE</h6>
                    </div>
                  </div>

                  <p className="card-text pt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                    leo.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="card">
                <div className="card-body my-5 px-5">
                  <div className="d-flex flex-sm-column flex-md-row flex-lg-row flex-xs-column flex-column">
                    <div>
                      <img className="carousel-img" src={img4} alt="card-img" />
                    </div>
                    <div className="ps-3 pt-3">
                      <h2>MICHELE MARIN</h2>
                      <h6>EMPLOYEE</h6>
                    </div>
                  </div>

                  <p className="card-text pt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                    leo.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                  <FontAwesomeIcon className="icon-style" icon={faStar} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ClientsFeedback;
