import React from 'react'
import Header from '../../components/Shared/Header/Header'
import service3 from '../../images/supply.jpg'
// import service2 from '../../images/manufacture.jpg'
import service2 from '../../images/manu.jpg'
import service1 from '../../images/design.jpg'
import HomeFooter from '../../components/Shared/Footer/HomeFooter'
import './Service.css'
import { Link } from 'react-router-dom'



const Services = () => {
  return (
    <div className='service-bg-color'>
        <Header />
        <div className="background-companyProfile">
         <div className="company-text">
          <h1 className="text-white fw-bold">
            <span className=" company">OUR SERVICES</span>
          </h1>

          <p className="text-white fs-5">
            <Link to="/home" className="text-white">
              HOME
            </Link>{" "}
            / SERVICES
          </p>
        </div>
      </div>
        <div className='d-flex flex-lg-row flex-md-row flex-sm-column flex-column text-center container my-5 gap-3'>
           <div className='service-card w-75  border rounded-4  pb-4 bg-white'>
            <img src={service1} className='w-100 rounded-4 border'/>
            <h2 className='pt-4 service-color'>DESIGN</h2>
            
              <span>Mechanical 3D Design</span><br />
              <span>Electrical PCB Design</span>
            
           </div>
           <div className='service-card w-75 gap-2 border rounded-4 pb-4 bg-white'>
           <img src={service2} className='w-100 rounded-4 border' />
            <h3 className='pt-4 service-color'>MANUFACTURING</h3>
            
              <span>Simulation</span><br />
              <span>3D Printing</span><br />
              <span>CNC Machining</span><br />
              <span>Maintenance</span>
           
           </div>
           <div className='service-card w-75 gap-2 border rounded-4  pb-4 bg-white'>
           <img src={service3} className='w-100 rounded-4 border'/>
            <h2 className='pt-4 service-color'>SUPPLY</h2>
            
              <span>Engineering Consultancy</span><br />
              <span>Export Import </span>

          
           </div>
        </div>
        <HomeFooter />
    </div>
  )
}

export default Services