import React from 'react';
import Partner2 from './Partners2';
import img1 from "../../images/govt.jpg";
import img2 from "../../images/spectrum.png";
import img3 from "../../images/a2i.jpg";
import img4 from "../../images/BUET_LOGO.svg";
import img5 from "../../images/Dhaka_University_logo.svg";
import img6 from "../../images/NFDDP.png";
import img7 from "../../images/AUGMEDIX.jpeg";
import img8 from "../../images/Bangladesh_Agricultural_University_(crest).png";
import img9 from "../../images/bangladesh agricultural research institute logo.png";
import img10 from "../../images/CRP.jpeg";
const logos = [img1,img2,img3,img4,img5,img6,img7,img8,img9,img10]; // Add more logo URLs as needed


const Partners = () => {
  return (
    <div>
      <h2 className="text-center fontColor pt-5 pb-2">PARTNERS</h2>
      <Partner2 logos={logos} />
    </div>
  );
};

export default Partners;
